<!--
 * @Author       : JiangChao
 * @Date         : 2022-08-26 19:59:51
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-21 10:48:19
 * @Description  : 綁定email信箱
-->
<template>
    <div class="container">
        <div class="email-check">
            <div>基本資料</div>
            <input
                id="email"
                class="width-fill-80"
                placeholder="請輸入暱稱"
                v-model="nickName"
            />
            <input
                id="email"
                type="email"
                class="width-fill-80"
                placeholder="請輸入電子信箱"
                v-model="email"
            />
            <button type="button" class="input-button" @click="$_submitEmail()">
                確認
            </button>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import { Toast } from "vant";

export default {
    data() {
        return {
            email: "",
            nickName: "",
        };
    },
    methods: {
        async $_submitEmail() {
              if (!this.nickName) {
                Toast("請輸入暱稱");
                return;
            }
            if (!this.email) {
                Toast("請輸入電子信箱");
                return;
            }
          
            try {
                const { data } = await this.$axios.post("customer/upUserInfo", {
                    email: this.email,
                    nickName: this.nickName,
                });
                if (data.success) {
                     Toast(data.result);
                    this.$router.push("map");
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
};
</script>

<style lang="css" scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.email-check {
    width: calc(100% - 64px);
    margin: 0 32px;
    height: 150px;
    background: #ffffff;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.width-fill-80 {
    width: 80%;
}
</style>
>
